<template>
	<main class="about">
		<h1>Description</h1>
		<p>The Mana World (TMW) is a serious effort to create an innovative free and open source MMORPG. TMW uses 2D graphics and aims to create a large and diverse interactive world. It is licensed under the GPL, making sure this game can't ever run away from you.</p>
		<p>Explore this large, ever expanding world to defeat monsters, help NPCs and team up with friends as you achieve your goals. Get your weapons, armor and equipment through quests, monsters or crafting. Play mini-games, go on complex investigations or slay powerful bosses. Hang out in town, socialize or attend player organized events. Wear your boots and grab your sword, adventure waits for you!</p>

		<h1>Story</h1>
		<p>Start in an island at the small continent of Argaes, where magic is born. Get powerful and explore the powerful city-state of Tulimshar before heading out into the vast expanses of the desert. In The Mana World you are an adventurer and monster slayer defending the people of the world from the threats created during the Great Quake. Even in the icy heights of Nivalis there is a call for your assistance to keep the world safe and to grow your potential.</p>
		<p>The Mage Council of Tulimshar has monitored events following the Great Quake and feel that something ominous is spreading throughout The Mana World. Monsters seem to of come out of every shadowy corner and petty dieties has begun to make presence in dark places. The council has made the call and you are just one of the many people that will battle the forces of evil, sending them back to the depths they came from.</p>
		<p>Be it warrior, archer or mage, you have answered the call from the leaders of the world to fight back the darkness that spread after the Great Quake. Starting in the Tonori desert, you battle your way to forests surrounding Hurnscald or the icy mountains surrounding Nivalis. Monsters have nowhere to hide.</p>

		<h1>Contributors</h1>
		<p>We are volunteer driven and encourage player participation in development. We have a long history of contributors. We try to make contributing to the game easy.</p>
		<p>
			<ul>
				<li><a href="https://wiki.themanaworld.org/index.php/TMW_Team">TMW Team</a></li>
				<li><a href="https://gitlab.com/groups/evol/-/group_members">Current contributors</a></li>
				<li><a href="https://wiki.themanaworld.org/index.php/Dev:Contributors">Past contributors</a></li>
			</ul>
		</p>
	</main>
</template>

<!-- This content very quickly becomes stales so we might want to (somehow)
     import from the wiki, or redirect to the wiki entirely -->
